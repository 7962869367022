import * as React from "react";

function FacebookMobile(props) {
  return (
    <a
    href="https://www.facebook.com/ScholasticArtandWritingAwards"
    target="_blank"
    rel="noopener noreferrer"
  >
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.47586 14.95H2.89618C1.95044 14.95 1.23926 14.2158 1.23926 13.3774V2.57258C1.23926 1.73424 1.95044 1 2.89618 1H14.0553C15.001 1 15.7122 1.73424 15.7122 2.57258V13.3774C15.7122 14.2158 15.001 14.95 14.0553 14.95H11.9113V10.6393L13.7414 10.6396C14.2909 10.6397 14.7375 10.1964 14.7416 9.64699L14.7598 7.18935C14.7618 6.92289 14.6573 6.66665 14.4696 6.47752C14.2819 6.28838 14.0265 6.18198 13.76 6.18193L11.9498 6.18162L11.9577 5.78914L13.7836 5.78953C14.3351 5.78965 14.7826 5.34331 14.7838 4.79185L14.7896 2.31764C14.7902 2.05202 14.6851 1.79708 14.4975 1.60904C14.3099 1.421 14.0552 1.31532 13.7896 1.31532H11.6016C9.12704 1.31532 7.52881 3.41189 7.52384 5.71467L7.51429 6.18337L6.10374 6.18193C5.55395 6.18138 5.10683 6.62475 5.10275 7.17452L5.08452 9.63216C5.08255 9.89851 5.18692 10.1546 5.37449 10.3438C5.56205 10.5329 5.81732 10.6394 6.08368 10.6396L7.47586 10.6407V14.95Z" fill="#410098" stroke="#410098" stroke-width="2" stroke-linejoin="round"/>
</svg>

    </a>
  );
}

export default FacebookMobile;