import { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Grid,
  Spacer,
  extendTheme,
} from '@chakra-ui/react';

// new attempt
// import { MobileLogo } from './components/mobilePoster';
import Copy from './details';
import CopyMobile from './details-mobile';
import Mobile from './components/mobilePoster';
import Tablet from './components/tabletPoster';
import BrandMobile from './components/mobilelogo';
import Brand from './scholasticLogo';
import Poster from './components/poster';
import HowToEnterTablet from './components/howtoentertablet';
import HowToEnterMobile from './components/howtoentermobile';
import HowToEnter from './components/howtoenter';
import EnterNow from './components/enternow';
import EnterNowMobile from './enternowmobile';


import Instagram from './components/social/instagram';
import IgTablet from './components/social/igTablet';
import IgMobile from './components/social/igmobile';
import TikTok from './components/social/tiktok';
import TikTokTablet from './components/social/tiktoktablet';
import TikTokMobile from './components/social/tiktokMobile';
import Facebook from './components/social/facebook';
import FacebookTablet from './components/social/facebookTablet';
import FacebookMobile from './components/social/facebookMobile';
// import { Logo } from './components/Logo';
import HowToButton from './components/howtobutton';
import HowToMobile from './howtomobile';

import ReactGA from "react-ga4";

ReactGA.initialize("G-2HBNHZGL0T");
ReactGA.send("pageview");

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#FFEC00',
        color: 'white',
      },
    },
  },
});

function App() {
  const size = useWindowSize();

  return (
    // previous work!
    <ChakraProvider theme={theme}>
      <Box h={size.height} justifyContent="center" alignItems="center" p={2} display={{ md: 'flex' }}>
        {size.width < 750 ? (
          <MobileView size={size} />
        ) : size.width <= 1200 ? (
          <TabletView size={size} />
        ) : (
          <DesktopView size={size} />
        )}
      </Box>
    </ChakraProvider>
  );
}

export default App;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return windowSize;
}

// Mobile View Component
function MobileView({ size }) {
  return (
    <>
      <Flex flexDirection="row" alignItems="start" pl={10} mt={5}>

      
      </Flex>
      <Flex
        height='80%'
        width='100%'
        flexDirection="column"
        alignItems="center"
        // justifyContent="center"
 // no margin below Poster
      >
        <Box w="10px" h="25px"/>
          <Mobile />
          <Box w="10px" h="45px"/>
        <HowToEnterMobile />
        <Box w="10px" h="30px"/>
          <CopyMobile />
        <Box w="10px" h="30px"/>
        <Flex
          width='80%'
          flexDirection="row"
          justifyContent="space-around"
        >
        <HowToMobile/>
        <EnterNowMobile/>
        </Flex>

      <Flex
        mt={10}
        mb={10}
        width="30%"
        flexDirection="row"
        justifyContent="space-between"
        >
        <IgMobile />
        <TikTokMobile />
        <FacebookMobile />
      </Flex>
      <BrandMobile pl={3} mt={5}/>
    </Flex>
    </>
  );
}

// Tablet View Component
function TabletView({ size }) {
  return (
    <>    
        
        {/* <Box position="absolute" top='5%' right="50%" mb={10} pb={12}> */}
        {/* </Box> */}
      <Flex
        height='100%'
        width='80%'
        flexDirection="column"
        alignItems="center"
        mt={3}
      >
        {/* <Flex flexDirection="row" alignItems="left" ml={0} mt={5}> */}
        {/* </Flex> */}
        <Box w="10px" h="50px"/>
        <Tablet mt={2}/>
        <Box w="10px" h="45px"/>
        <HowToEnterTablet width={300} height={150}/>
        <Box w="10px" h="50px"/>
        <Copy />
        <Box w="10px" h="50px"/>
        <Flex
          flexDirection='row'
          width='80%'
          justifyContent='space-between'
        >
        <HowToButton width={326} height={73}/>
        <Box w="10px" h="30px"/>
        <EnterNow />
        </Flex>
      <Box w="10px" h="50px"/>
        <Flex
          flexDirection="row"
          width="50%"
          justifyContent="space-between"
          // alignItems="center"
          mt={4}
        >
          <IgTablet />
          <TikTokTablet />
          <FacebookTablet />
        </Flex>
        <Box w="10px" h="30px"/>
        <Brand />
    </Flex>
    </>
  );
}

// Desktop View Component
function DesktopView({ size }) {
  return (
    <>
    <Flex 
      flexDirection="column"
      alignItems="center"
      mt={2}
    >
        <Poster size={size} />
        <Box h="10px"></Box>
    </Flex>
      <Flex
        flexDirection="column"
        align="center"
        ml={{ md: 6, lg: 16 }}
      >
        <Spacer />
        <Flex flexDirection="column" alignItems='center' spacing={8}>
          {/* <Box h="30px"/> */}
          <HowToEnter width={462} height={121} />
          <Box h="45px" />
          <Copy />
          <Box h="45px" />
          <HowToButton width={326} height={73}/>
          <Box h="45px" />
          <EnterNow />
          <Box h="45px"/>
          {/* <Grid templateColumns='repeat(3, 1fr)' gap={20} mt={3} px={10}> */}
          <Flex
            width="40%"
            flexDirection="row"
            justifyContent="space-around"
          >
            <Instagram />
            <TikTok />
              <Facebook />
          </Flex>
          </Flex>
        <Box h="50px" />
        <Brand />
      </Flex>
    </>
  );
}