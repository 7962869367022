// https://www.tiktok.com/@artandwritingawards?lang=en
import * as React from "react";

function TikTokMobile(props) {
  return (
    <a
    href="https://www.tiktok.com/@artandwritingawards?lang=en"
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8318 3.22882C10.9726 2.6685 10.3524 1.77236 10.159 0.727613C10.1172 0.50178 10.0943 0.269556 10.0943 0.0317383H7.3521L7.34758 11.0214C7.3015 12.252 6.28872 13.2398 5.0469 13.2398C4.66101 13.2398 4.29749 13.1434 3.97765 12.9748C3.24396 12.5886 2.74196 11.8195 2.74196 10.9346C2.74196 9.66347 3.77605 8.62938 5.0469 8.62938C5.28418 8.62938 5.51161 8.66853 5.72706 8.7359V5.93642C5.50416 5.90606 5.27779 5.88716 5.0469 5.88716C2.26419 5.88742 0 8.15161 0 10.9346C0 12.6422 0.853267 14.1532 2.15474 15.067C2.97445 15.6425 3.97179 15.9817 5.04716 15.9817C7.83014 15.9817 10.0943 13.7175 10.0943 10.9346V5.36199C11.1697 6.13403 12.4874 6.58889 13.9093 6.58889V3.84667C13.1433 3.84667 12.4299 3.61897 11.8318 3.22882Z" fill="#410098"/>
    </svg>

    </a>
  );
}

export default TikTokMobile;