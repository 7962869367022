import * as React from "react";
import { ReactComponent as MobileLogo } from '../oc-mobile.svg';
import { Box } from '@chakra-ui/react';

function Mobile(props) {
  console.log("🚀 ~ file: poster.js ~ line 4 ~ Poster ~ props", props);

  return (
    <Box width="90%" height="auto" display="flex" justifyContent="center" alignItems="center">
      <a
    href="https://www.artandwriting.org/awards/"
    target="_blank"
    rel="noopener noreferrer"
    >

    <MobileLogo />

    {/* 2024 below */}

    </a>
    </Box>

    
  );
}

export default Mobile;