import * as React from "react";
import { ReactComponent as Logo } from '../oc-desk-resize.svg';
// import { Box } from '@chakra-ui/react';

function Poster(props) {
  console.log("🚀 ~ file: poster.js ~ line 4 ~ Poster ~ props", props);
// width="90%" height="75%" 
  return (
    // <Box  width="90%" height="75%" display="flex" justifyContent="center" alignItems="center">
      <a
    href="https://www.artandwriting.org/awards/"
    target="_blank"
    rel="noopener noreferrer"
    >

    <Logo />

    {/* 2024 below */}

    </a>
    // </Box>

    
  );
}

export default Poster;