import * as React from "react";

function IgTablet(props) {
  return (
    <a
    href="https://www.instagram.com/artandwriting/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg width="37" height="35" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.4322C0 4.67065 4.81661 0 10.7582 0H25.3893C31.3309 0 36.1475 4.67065 36.1475 10.4322V24.5678C36.1475 30.3294 31.3309 35 25.3893 35H10.7582C4.81661 35 0 30.3294 0 24.5678V10.4322ZM3.33504 10.0149C3.33504 6.2699 6.46584 3.23398 10.3279 3.23398H25.8197C29.6817 3.23398 32.8125 6.2699 32.8125 10.0149V25.0894C32.8125 28.8344 29.6817 31.8703 25.8197 31.8703H10.3279C6.46584 31.8703 3.33504 28.8344 3.33504 25.0894V10.0149ZM29.854 8.11103C29.854 9.27774 28.8786 10.2235 27.6755 10.2235C26.4723 10.2235 25.4969 9.27774 25.4969 8.11103C25.4969 6.94432 26.4723 5.99851 27.6755 5.99851C28.8786 5.99851 29.854 6.94432 29.854 8.11103ZM18.0738 26.4978C23.2132 26.4978 27.3796 22.4577 27.3796 17.4739C27.3796 12.4902 23.2132 8.45007 18.0738 8.45007C12.9343 8.45007 8.76793 12.4902 8.76793 17.4739C8.76793 22.4577 12.9343 26.4978 18.0738 26.4978ZM18.1007 23.3159C21.4428 23.3159 24.1522 20.6887 24.1522 17.4478C24.1522 14.207 21.4428 11.5797 18.1007 11.5797C14.7585 11.5797 12.0492 14.207 12.0492 17.4478C12.0492 20.6887 14.7585 23.3159 18.1007 23.3159Z" fill="#410098"/>
</svg>
    </a>
  );
}

export default IgTablet;