import * as React from "react";

function FacebookTablet(props) {
  return (
    <a
    href="https://www.facebook.com/ScholasticArtandWritingAwards"
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.1985 32H5.65432C3.14 32 1.15723 30.0345 1.15723 27.6774V5.32258C1.15723 2.96546 3.14 1 5.65432 1H28.7421C31.2564 1 33.2392 2.96546 33.2392 5.32258V27.6774C33.2392 30.0345 31.2564 32 28.7421 32H23.2374V20.9431L28.093 20.944C28.6424 20.944 29.089 20.5008 29.0931 19.9514L29.1308 14.8666C29.1328 14.6001 29.0284 14.3439 28.8406 14.1548C28.6529 13.9656 28.3975 13.8592 28.131 13.8592L23.2953 13.8583L23.3364 11.8094L23.3366 11.7893C23.3366 11.5182 23.4271 11.225 23.553 11.0161C23.5811 10.9696 23.6067 10.9342 23.6278 10.9084L28.1803 10.9094C28.7318 10.9095 29.1792 10.4632 29.1805 9.91169L29.1924 4.79264C29.193 4.52702 29.0879 4.27207 28.9003 4.08404C28.7127 3.896 28.458 3.79032 28.1924 3.79032H23.6656C19.2564 3.79032 16.3025 7.52396 16.2977 11.8353L16.2564 13.8632L12.2896 13.8592C11.7398 13.8586 11.2926 14.302 11.2886 14.8518L11.2509 19.9365C11.2489 20.2029 11.3532 20.459 11.5408 20.6481C11.7284 20.8373 11.9837 20.9437 12.25 20.944L16.1985 20.9472V32ZM23.683 10.8512C23.683 10.8512 23.6823 10.8517 23.681 10.8527C23.6823 10.8516 23.683 10.8512 23.683 10.8512Z" fill="#410098" stroke="#410098" stroke-width="2" stroke-linejoin="round"/>
</svg>

    </a>
  );
}

export default FacebookTablet;