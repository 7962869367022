import * as React from "react";

function Facebook(props) {
  return (
    <a
    href="https://www.facebook.com/ScholasticArtandWritingAwards"
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.1982 32H5.65407C3.13976 32 1.15698 30.0345 1.15698 27.6774V5.32258C1.15698 2.96546 3.13976 1 5.65407 1H28.7419C31.2562 1 33.2389 2.96546 33.2389 5.32258V27.6774C33.2389 30.0345 31.2562 32 28.7419 32H23.2371V20.9431L28.0927 20.944C28.6422 20.944 29.0888 20.5008 29.0929 19.9514L29.1306 14.8666C29.1326 14.6001 29.0281 14.3439 28.8404 14.1548C28.6527 13.9656 28.3973 13.8592 28.1308 13.8592L23.2951 13.8583L23.3362 11.8094L23.3364 11.7893C23.3364 11.5181 23.4268 11.225 23.5528 11.0161C23.5809 10.9696 23.6065 10.9342 23.6275 10.9084L28.1801 10.9094C28.7315 10.9095 29.179 10.4632 29.1803 9.91169L29.1921 4.79264C29.1928 4.52702 29.0877 4.27207 28.9001 4.08404C28.7125 3.896 28.4578 3.79032 28.1922 3.79032H23.6654C19.2562 3.79032 16.3023 7.52396 16.2975 11.8353L16.2561 13.8632L12.2893 13.8592C11.7395 13.8586 11.2924 14.302 11.2883 14.8518L11.2506 19.9365C11.2486 20.2029 11.353 20.459 11.5406 20.6481C11.7281 20.8373 11.9834 20.9437 12.2498 20.944L16.1982 20.9472V32ZM23.6827 10.8512C23.6827 10.8512 23.6821 10.8518 23.6808 10.8527C23.6821 10.8516 23.6827 10.8512 23.6827 10.8512Z" fill="#410098" stroke="#410098" stroke-width="2" stroke-linejoin="round"/>
</svg>
    </a>
  );
}

export default Facebook;

// import * as React from "react";

// function Facebook(props) {
//   return (
//     <a
//     href="https://www.facebook.com/ScholasticArtandWritingAwards"
//     target="_blank"
//     rel="noopener noreferrer"
//   >
//     <svg
//       width={62}
//       height={61}
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//       {...props}
//     >
//       <g filter="url(#prefix__filter0_d_845_417)">
//         <path
//           d="M29.55 50.79H12.86c-4.002 0-7.162-3.128-7.162-6.887V8.678c0-3.759 3.16-6.887 7.162-6.887h36.38c4.002 0 7.163 3.128 7.163 6.887v35.225c0 3.759-3.16 6.888-7.162 6.888h-8.75V33.216l7.727.001a1.5 1.5 0 001.5-1.488l.06-8.013a1.5 1.5 0 00-1.5-1.511l-7.697-.001.066-3.306v-.03c0-.442.147-.918.352-1.258.056-.093.108-.16.147-.206l7.21.001a1.5 1.5 0 001.5-1.496l.018-8.067a1.5 1.5 0 00-1.5-1.503h-7.133c-6.897 0-11.527 5.84-11.534 12.602l-.067 3.27-6.325-.006a1.5 1.5 0 00-1.501 1.489l-.06 8.012a1.5 1.5 0 001.5 1.511l6.297.005v17.569zm11.675-33.462l-.004.002a.03.03 0 01.004-.002z"
//           stroke="#fff"
//           strokeWidth={3}
//           strokeLinejoin="round"
//           shapeRendering="crispEdges"
//         />
//       </g>
//       <defs>
//         <filter
//           id="prefix__filter0_d_845_417"
//           x={0.198}
//           y={0.291}
//           width={61.705}
//           height={60}
//           filterUnits="userSpaceOnUse"
//           colorInterpolationFilters="sRGB"
//         >
//           <feFlood floodOpacity={0} result="BackgroundImageFix" />
//           <feColorMatrix
//             in="SourceAlpha"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//             result="hardAlpha"
//           />
//           <feOffset dy={4} />
//           <feGaussianBlur stdDeviation={2} />
//           <feComposite in2="hardAlpha" operator="out" />
//           <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
//           <feBlend
//             in2="BackgroundImageFix"
//             result="effect1_dropShadow_845_417"
//           />
//           <feBlend
//             in="SourceGraphic"
//             in2="effect1_dropShadow_845_417"
//             result="shape"
//           />
//         </filter>
//       </defs>
//     </svg>
//     </a>
//   );
// }

// export default Facebook;