import * as React from "react";

function TikTok(props) {
  return (
    <a
    href="https://www.tiktok.com/@artandwritingawards?lang=en"
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.8545 7.01555C24.9692 5.786 23.6082 3.81954 23.1839 1.527C23.0922 1.03144 23.0419 0.521856 23.0419 0H17.0245L17.0146 24.1152C16.9135 26.8157 14.691 28.9832 11.9661 28.9832C11.1193 28.9832 10.3216 28.7716 9.61975 28.4017C8.00976 27.5543 6.9082 25.8666 6.9082 23.9247C6.9082 21.1355 9.17737 18.8663 11.9661 18.8663C12.4867 18.8663 12.9858 18.9522 13.4586 19.1V12.957C12.9694 12.8904 12.4727 12.8489 11.9661 12.8489C5.85981 12.8495 0.891357 17.8179 0.891357 23.9247C0.891357 27.6718 2.76373 30.9876 5.61962 32.9926C7.41836 34.2555 9.60689 35 11.9666 35C18.0735 35 23.0419 30.0316 23.0419 23.9247V11.6965C25.4017 13.3906 28.2932 14.3887 31.4133 14.3887V8.37132C29.7326 8.37132 28.167 7.87167 26.8545 7.01555Z" fill="#410098"/>
</svg>

    </a>
  );
}

export default TikTok;
