import * as React from "react";

function IgMobile(props) {
  return (
    <a
    href="https://www.instagram.com/artandwriting/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.78584C0 2.16022 2.195 0.0317383 4.90266 0.0317383H11.5703C14.278 0.0317383 16.4729 2.16022 16.4729 4.78584V11.2276C16.4729 13.8533 14.278 15.9817 11.5703 15.9817H4.90266C2.195 15.9817 0 13.8533 0 11.2276V4.78584ZM1.51983 4.59567C1.51983 2.88902 2.94657 1.50551 4.70656 1.50551H11.7664C13.5264 1.50551 14.9531 2.88902 14.9531 4.59567V11.4653C14.9531 13.172 13.5264 14.5555 11.7664 14.5555H4.70656C2.94657 14.5555 1.51983 13.172 1.51983 11.4653V4.59567ZM13.6049 3.72805C13.6049 4.25974 13.1604 4.69075 12.6121 4.69075C12.0638 4.69075 11.6193 4.25974 11.6193 3.72805C11.6193 3.19636 12.0638 2.76534 12.6121 2.76534C13.1604 2.76534 13.6049 3.19636 13.6049 3.72805ZM8.23647 12.1071C10.5786 12.1071 12.4773 10.266 12.4773 7.99485C12.4773 5.72369 10.5786 3.88256 8.23647 3.88256C5.89434 3.88256 3.99567 5.72369 3.99567 7.99485C3.99567 10.266 5.89434 12.1071 8.23647 12.1071ZM8.24873 10.6571C9.77179 10.6571 11.0065 9.45988 11.0065 7.98297C11.0065 6.50606 9.77179 5.30879 8.24873 5.30879C6.72567 5.30879 5.49098 6.50606 5.49098 7.98297C5.49098 9.45988 6.72567 10.6571 8.24873 10.6571Z" fill="#410098"/>
    </svg>
    </a>
  );
}

export default IgMobile;