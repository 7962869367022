// https://www.tiktok.com/@artandwritingawards?lang=en
import * as React from "react";

function TikTokTablet(props) {
  return (
    <a
    href="https://www.tiktok.com/@artandwritingawards?lang=en"
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.8547 7.01555C24.9695 5.786 23.6084 3.81954 23.1842 1.527C23.0924 1.03144 23.0422 0.521856 23.0422 0H17.0247L17.0148 24.1152C16.9137 26.8157 14.6913 28.9832 11.9663 28.9832C11.1195 28.9832 10.3218 28.7716 9.61999 28.4017C8.01001 27.5543 6.90844 25.8666 6.90844 23.9247C6.90844 21.1355 9.17761 18.8663 11.9663 18.8663C12.487 18.8663 12.9861 18.9522 13.4588 19.1V12.957C12.9697 12.8904 12.473 12.8489 11.9663 12.8489C5.86005 12.8495 0.891602 17.8179 0.891602 23.9247C0.891602 27.6718 2.76397 30.9876 5.61987 32.9926C7.41861 34.2555 9.60713 35 11.9669 35C18.0737 35 23.0422 30.0316 23.0422 23.9247V11.6965C25.4019 13.3906 28.2935 14.3887 31.4135 14.3887V8.37132C29.7328 8.37132 28.1672 7.87167 26.8547 7.01555Z" fill="#410098"/>
    </svg>`

    </a>
  );
}

export default TikTokTablet;